import React from "react";
import { RedocStandalone } from "redoc";

const COLORS = {
  Primary: "#9DD825",
  Secondary: "#4ADE80",
  Tertiary: "#D7DF23",

  BackgroundLigh: "#2B3E49",
  BackgroundMedium: "#1F313F",
  BackgroundDark: "#121D26",

  Text: "#FCFCFC",

  CODE: { Block: "#121D26" },
};

function App() {
  return (
    <div className="App">
      <RedocStandalone
        specUrl={`${process.env.PUBLIC_URL}/swagger.json`}
        options={{
          hideHostname: true,
          nativeScrollbars: false,
          hideDownloadButton: true,

          theme: {
            logo: { gutter: "30px" },

            colors: {
              gray: { 100: COLORS.BackgroundDark },
              text: { primary: COLORS.Text },
              primary: { main: COLORS.Primary },
              responses: { success: { color: COLORS.Secondary } },
            },

            sidebar: {
              textColor: COLORS.Text,
              activeTextColor: COLORS.Secondary,
              backgroundColor: COLORS.BackgroundDark,
            },

            codeBlock: {
              backgroundColor: COLORS.BackgroundDark,
            },

            schema: {
              linesColor: COLORS.Tertiary,
              typeNameColor: COLORS.Secondary,
              requireLabelColor: COLORS.Tertiary,
              nestedBackground: COLORS.BackgroundMedium,
            },

            fab: {
              backgroundColor: "red",
              color: "red",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
